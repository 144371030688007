import React from 'react';
import './Segment.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import sprite from '../../../static/sprite.svg';

const Segment = ({
  imageData,
  heading,
  description,
  graphicLocation = 'LEFT',
  learnMoreCta = null
}) => {
  const graphicClass =
    graphicLocation === 'RIGHT' ? 'segment__icon-box--right' : '';
  const containerClass = graphicLocation === 'LEFT' ? 'segment--right' : '';
  const imagesClass = graphicLocation === 'LEFT' ? 'segment__image--right' : '';

  return (
    <section className={`segment ${containerClass}`}>
      <div className="segment__illustration">
        <span className={`segment__icon-box ${graphicClass}`}>
          <svg className="segment__icon">
            <use href={`${sprite}#site-graphic`} />
          </svg>
        </span>
        <GatsbyImage
          image={imageData}
          alt="illustration image"
          class={`segment__image ${imagesClass}`}
        />
      </div>
      <div className="segment__text">
        <h2 className="segment__heading heading-secondary">{heading}</h2>
        {description.map((para, index) => (
          <p key={index} className="para">{para}</p>
        ))}
        {learnMoreCta && (
          <a
            href={learnMoreCta}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button label="Learn more" type="button"></Button>
          </a>
        )}
      </div>
    </section>
  );
};

export default Segment;
