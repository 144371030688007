import Layout from '../components/Layout/Layout';
import React from 'react';
import './about.scss';
// import Button from '../components/Button/Button';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Segment from '../components/Segment/Segment';
import AmazonSmile from '../components/AmazonSmile/AmazonSmile';
import Intro from '../components/Intro/Intro';

const About = ({ data }) => {
  return (
    <Layout>
      <Intro
        heading={data.aboutText.childTextContentJson.about.heading}
        description={data.aboutText.childTextContentJson.about.description}
        // imageData={data.aboutImage.childImageSharp.gatsbyImageData}
        // button={<Button label="Nominate a Patient" type="button" />}
      />

      <Segment
        heading={data.storyTexts.childTextContentJson.stories[0].heading}
        description={
          data.storyTexts.childTextContentJson.stories[0].description
        }
        imageData={data.storyImages.nodes[0].childImageSharp.gatsbyImageData}
        graphicLocation="RIGHT"
      />
      <Segment
        heading={data.storyTexts.childTextContentJson.stories[1].heading}
        description={
          data.storyTexts.childTextContentJson.stories[1].description
        }
        imageData={data.storyImages.nodes[1].childImageSharp.gatsbyImageData}
        graphicLocation="LEFT"
        learnMoreCta={
          data.storyTexts.childTextContentJson.stories[1].learnMoreCta
        }
      />
      <Segment
        heading={data.storyTexts.childTextContentJson.stories[2].heading}
        description={
          data.storyTexts.childTextContentJson.stories[2].description
        }
        imageData={data.storyImages.nodes[2].childImageSharp.gatsbyImageData}
        graphicLocation="RIGHT"
      />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutText: file(relativePath: { eq: "about.json" }) {
      childTextContentJson {
        about {
          description
          heading
        }
      }
    }
    aboutImage: file(relativePath: { eq: "about.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    storyTexts: file(relativePath: { eq: "about.json" }) {
      childTextContentJson {
        stories {
          description
          heading
          learnMoreCta
        }
      }
    }
    storyImages: allFile(
      filter: { relativePath: { regex: "/about-story/" } }
      sort: { fields: relativePath, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
